<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">党员数量</span>
          </div>
          <div class="chart flex-11" ref="chart1"></div>
        </div>
        <div class="mod2 flex flex-v">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">开展活动数量</span>
          </div>
          <div class="chart flex-11" ref="chart2"></div>
        </div>
        <div class="mod7 flex flex-v">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">领导关怀</span>
          </div>
          <div class="swiper-container swiper mySwiper3">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy03.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy04.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy02.png" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy08.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy08.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy02.png" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy03.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy05.jpg" alt="" />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img src="https://home/ResImg/dj_jy08.jpg" alt="" />
              </div>
            </div>
          </div>
          <!-- <div class="s-scroll-list flex-1 flex flex-v">
            <div class="row row-t">
              <div style="width: 37%">{{ volunteerAbout.head1 }}</div>
              <div style="width: 28%">{{ volunteerAbout.head3 }}</div>
            </div>
            <vue-seamless-scroll
              :data="volunteerList"
              :class-option="scrollOption"
              class="flex-1"
            >
              <div class="scrollbox">
                <div
                  class="row row-b"
                  v-for="(item, index) in volunteerList1"
                  :key="index"
                >
                  <div style="width: 37%">{{ item.title }}</div>
                  <div style="width: 28%">{{ item.status }}</div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div> -->
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <!-- <div class="cen-menu">
            <span class="lk">新时代文明</span>
            <span class="lk cur">智慧党建</span>
            <span class="lk">智慧文旅</span>
            <span class="lk">双中心</span>
          </div> -->
        <ul class="cen-list cen-list-1">
          <li>
            <h6 class="title">2022党员数量<em>(名)</em></h6>
            <div class="txt">3377</div>
          </li>
          <li>
            <h6 class="title">2022支部数量<em>(个)</em></h6>
            <div class="txt">171</div>
          </li>
          <li>
            <h6 class="title">2022积极分子<em>(人)</em></h6>
            <div class="txt">106</div>
          </li>
        </ul>
        <ul class="cen-list cen-list-2 b">
          <li class="item1">
            <h6 class="title">
              五年开展党员活动<em>7966次</em>
              <!-- <img src="../assets/images/dangjian_up.png" alt="" /> -->
            </h6>
            <!-- <div class="txt">998,820</div> -->
          </li>
          <li class="item2">
            <h6 class="title">
              开展志愿活动<em>416次</em>
              <!-- <img src="../assets/images/dangjian_up.png" alt="" /> -->
            </h6>
            <!-- <div class="txt">373,860</div> -->
          </li>
        </ul>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod mod3">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">活动剪影</span>
          </div>
          <!-- <img src="../assets/imgs/dj_jy02.png" alt="">
          <img src="../assets/imgs/dj_jy03.jpg" alt="">
          <img src="../assets/imgs/dj_jy04.jpg" alt="">
          <img src="../assets/imgs/dj_jy05.jpg" alt="">
          <img src="../assets/imgs/dj_jy06.jpg" alt="">
          <img src="../assets/imgs/dj_jy08.jpg" alt=""> -->
          <ul class="mod3_list">
            <li class="mod3_item">
              <div class="tit" title="党员志愿者参与小区疫情防控工作">
                党员志愿者参与小区疫情防控工作
              </div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/01.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者节假日配合景区做好疫情防控工作">
                党员志愿者节假日配合景区做好疫情防控工作
              </div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/02.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="党员志愿者在敬老院开展义剪活动">
                党员志愿者在敬老院开展义剪活动
              </div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/03.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="红漾南湖党建行示范线路推介启动仪式">红漾南湖党建行示范线路推介启动仪式</div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/04.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="南湖景区志愿服务临时党支部">南湖景区志愿服务临时党支部</div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/05.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="社区党员志愿者在辖区景区开展志愿服务活动">社区党员志愿者在辖区景区开展志愿服务活动</div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/06.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="社区党支部开展“学雷锋”志愿者活动">社区党支部开展“学雷锋”志愿者活动</div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/07.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="小区功能型党支部成立暨选举大会">小区功能型党支部成立暨选举大会</div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/08.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="小区功能型党支部开展主题党日活动">
                小区功能型党支部开展主题党日活动
              </div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/09.jpg" alt="" />
              </div>
            </li>
            <li class="mod3_item">
              <div class="tit" title="红漾南湖党建主题公园">红漾南湖党建主题公园</div>
              <div class="cont">
                <img class="mod3_img" src="https://home/ResImg/10.jpg" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_dj.vue";
let Echarts = require("echarts/lib/echarts");
import { borderBox1 } from "@jiaminghi/data-view";
import Vue from "vue";
Vue.use(borderBox1);
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import axios from "axios";
export default {
  data() {
    return {
      // headTitle: "",
      headTitle: "智 慧 党 建",
      webData: {},
      mySwiper3: [],
      serviceAbout: {},
      munberList: [],
      volunteerAbout: {},
      volunteerList: [],
      volunteerList1: [
        {
          title: "xxx文明事件",
          status: "处理中",
        },
        {
          title: "xxx文明事件",
          status: "处理中",
        },
        {
          title: "xxx文明事件",
          status: "处理中",
        },
        {
          title: "xxx文明事件",
          status: "处理中",
        },
      ],
      partyMemberNum: "",
      hour: "",
      threeMeeting: "",
      mumberNum: "",
      partyBranchNum: "",
      wpr: "",
      we: "",
      mod3_imgList: [
        {
          id: 2,
          url: "https://home/ResImg/dj_jy02.png",
        },
        {
          id: 3,
          url: "https://home/ResImg/dj_jy03.jpg",
        },
        {
          id: 4,
          url: "https://home/ResImg/dj_jy04.jpg",
        },
        {
          id: 5,
          url: "https://home/ResImg/dj_jy05.jpg",
        },
        {
          id: 6,
          url: "https://home/ResImg/dj_jy06.jpg",
        },
        {
          id: 8,
          url: "https://home/ResImg/dj_jy08.jpg",
        },
      ],
      monitorList: [
        {
          img: require("../assets/images/img_05.gif"),
          title: "榕江县水尾乡中心校正门",
        },
        {
          img: require("../assets/images/img_06.gif"),
          title: "榕江县第一中学正门",
        },
        {
          img: require("../assets/images/img_07.gif"),
          title: "榕江县示范幼儿园操场",
        },
        {
          img: require("../assets/images/img_05.gif"),
          title: "榕江县第四初级中学东门",
        },
      ],
      scroll_dang: [],
      scrollList: [],
      chart1: null,
      tootipTimer1: "",
      chart2: null,
      tootipTimer2: "",
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    // this.getData();
    // this.getServiceAbout();
    // this.getmunberList();
    // this.getvolunteerAbout();
  },
  mounted() {
    this.mySwiper3 = new Swiper(".swiper-container.mySwiper3", {
      slidesPerView: 4,
      spaceBetween: 20,
      speed: 3000,
      autoplay: true,
      loop: true,
    });
    this.init1();
    this.init2();
    // this.init3();
    // this.init4();
    // this.init5();
    // this.init6();
    window.addEventListener("resize", () => {
      this.chart1.resize();
      this.chart2.resize();
      this.mySwiper3.resize();
      // this.chart3.resize();
      // this.chart4.resize();
      // this.chart5.resize();
      // this.chart6.resize();
    });
  },

  methods: {
    // getData() {
    //   axios
    //     .get("/10zhdj.json")
    //     .then((res) => {
    //       // 处理响应
    //       // console.log("数据:"+res.data);
    //       let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
    //       // console.log(dataObject.data);
    //       //
    //       let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
    //       // console.log(jsonString) // 输出字符串类型的 JSON 数据
    //       let jsonObject = JSON.parse(jsonString);
    //       this.webData = jsonObject;
    //       //标题
    //     })
    //     .catch((error) => {
    //       console.log(error.response.status);
    //       console.log(error.response.data);
    //       console.log(error.response.headers);
    //     });
    // },
    // 团

    init1() {
      this.chart1 = Echarts.init(this.$refs.chart1);
      let option = {
        title: {
          text: "单位为:人",
          right: nowSize(10),
          top: nowSize(1),
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(5),
          top: nowSize(20),
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: ["2018年", "2019年", "2020年", "2021年", "2022年"],
            // data:chart6Year,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(20),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            max: 4000,
            interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(16),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(20), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#fff54f"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(16),
              },
            },
            showBackground: false,
            barWidth: nowSize(40),
            data: [1881, 2027, 2203, 3117, 3377],
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#fff54f",
                  },
                  {
                    offset: 1,
                    color: "#fff54f",
                  },
                ]),
              },
            },
          },
        ],
      };
      this.chart1.setOption(option, true);

      this.tootipTimer1 && this.tootipTimer1.clearLoop();
      this.tootipTimer1 = 0;
      this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
    },
    init2() {
      this.chart2 = Echarts.init(this.$refs.chart2);
      let option = {
        title: {
          text: "单位为:次",
          right: nowSize(10),
          top: nowSize(1),
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(5),
          top: nowSize(20),
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: ["2018年", "2019年", "2020年", "2021年", "2022年"],
            // data:chart6Year,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(20),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            max: 3000,
            interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(16),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(20), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#fff54f"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(16),
              },
            },
            showBackground: false,
            barWidth: nowSize(40),
            data: [1205, 1376, 1558, 1768, 2059],
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#fff54f",
                  },
                  {
                    offset: 1,
                    color: "#fff54f",
                  },
                ]),
              },
            },
          },
        ],
      };
      this.chart2.setOption(option, true);

      this.tootipTimer2 && this.tootipTimer8.clearLoop();
      this.tootipTimer2 = 0;
      this.tootipTimer8 = loopShowTooltip(this.chart2, option, {});
    },
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  /* background: url(https://media.shouxinfund.com/yueyang/bg_zhihuidangjian.png) no-repeat center; */
  background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/hunan/hengyang/photo/dangjian_bg.png);
  background-size: 100% 100%;
  background-color: #ba0b0c;
}

.mod1 {
  height: 38%;
  margin-bottom: 1%;
}
.mod2 {
  height: 38%;
}
.mod7 {
  margin-top: 2%;
  height: 25%;
  margin-bottom: 1%;
}
.mod3 {
  height: 98%;
  margin-bottom: 2%;
}

.mod1 .chart,
.mod2 .chart {
  flex: 1;
}

.mod1 .s-pub-ttt,
.mod2 .s-pub-ttt,
.mod3 .s-pub-ttt,
.mod7 .s-pub-ttt {
  background: url(../assets/images/dangjian_02.png);
  background-size: 100% 100%;
  padding-left: 100px;
  padding-right: 25px;
  align-items: center;
  height: 74px;
  margin-bottom: 30px;
}

.mod3 .s-scroll-list .scrollbox {
  height: 100%;
}
.mod2 .s-scroll-list .scrollbox {
  height: 33%;
}

.mod7 .mySwiper3 {
  width: 100%;
  /* height: 50%; */
  padding: 10px 0;
}
.mod7 .mySwiper3 .swiper-slide {
  width: 25%;
  height: 100%;
}
.mod7 .mySwiper3 .swiper-slide img {
  width: 100%;
  height: 100%;
}
.mod2 {
}
.mod2 .list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;
  height: 40%;
  margin-bottom: 30px;
}
.mod2 .list .item {
  position: relative;
  overflow: hidden;
}
.mod2 .list .title {
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 34px;
  padding: 15px 10px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #ff6363;
}

.mod2 .chart {
  height: calc(100% - 10px);
  flex: 1;
}
.mod {
  display: flex;
  flex-direction: column;
}
.mod .chart {
  height: calc(100% - 100px);
  flex: 1;
}
.mod3 .mod3_list {
  width: 100%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.mod3 .mod3_list .mod3_item {
  width: 35%;
  height: 21%;
}
.mod3 .mod3_list .mod3_item .cont {
  box-shadow: 6px 6px 10px 5px #980606;
  border: 2px solid #980606;
  border-radius: 20px;
  overflow: hidden;
  height: 74%;
}
.mod3 .mod3_list .mod3_item .tit {
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%; */

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.mod3 .mod3_list .mod3_item .mod3_img {
  width: 100%;
  height: 100%;
}
.cen-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 2%;
  color: #fff;
}
.cen-list li {
  text-align: center;
  width: 33%;
  margin-top: 20px;
  margin-left: 10px;
}
.cen-list.cen-list-1 .title {
  font-size: 44px;
  font-family: "RuiZiZhenYan";
  height: 70px;
  line-height: 70px;
  background: url(../assets/images/dangjian_01.png);
  background-size: 100% 100%;
}
.cen-list.cen-list-1 .title em {
  font-size: 30px;
  font-family: "RuiZiZhenYan";
}
.cen-list.cen-list-1 .txt {
  font-size: 52px;
  color: #fff54f;
  font-family: "PangMenZhengDao";
  margin-top: 10px;
}

.cen-list-2.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 40px;
}
.cen-list.b li {
  width: 50%;
}
.cen-list.cen-list-2 .item1 {
  background: url(../assets/images/dangjian_04.png);
  background-size: 100% 100%;
}
.cen-list.cen-list-2 .item2 {
  background: url(../assets/images/dangjian_05.png);
  background-size: 100% 100%;
}
.cen-list.cen-list-2 .title {
  font-size: 40px;
  margin-left: 105px;
  line-height: 100px;
  display: flex;
  align-items: center;
  padding-left: 140px;
}
.cen-list.cen-list-2 .title em {
  font-size: 52px;
  color: #fff54f;
  font-family: "PangMenZhengDao";

  margin-left: 30px;
}
.cen-list.cen-list-2 .title img {
  width: 30px;
  height: 30px;
}
.cen-map {
}
.cen-map .item {
  text-align: center;
}
.cen-map .title {
  font-size: 34px;
  color: #fff;
  margin-bottom: 15px;
}
.cen-map .tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 26px;
  color: #fd9d20;
  border: 1px solid #fd9d20;
  border-radius: 30px;
  padding: 0 25px;
  line-height: 50px;
}
.cen-map .icon {
  width: 120px;
  display: block;
  margin: 20px auto 0;
}

.cen-map .item1 {
  position: absolute;
  left: 10%;
  top: 65%;
}
.cen-map .item2 {
  position: absolute;
  left: 25%;
  top: 45%;
}
.cen-map .item3 {
  position: absolute;
  left: 30%;
  top: 28%;
}
.cen-map .item4 {
  position: absolute;
  left: 40%;
  top: 58%;
}
</style>
