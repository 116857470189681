<template>
	<div class="head">
		<span class="bt">{{headTitle}}</span>
		<div class="timebox">
			<span class="date">{{ nowDate }}</span>
			<span class="week">{{ nowWeek }}</span>
			<span class="time">{{ nowtime }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				nowDate: "",
				nowWeek: "",
				nowtime: "",
			}
		},
		components: {


		},
		props: {
			headTitle: {
				type: String
			},
			
		},
		computed: {

		},
		created() {
			this.nowTimes();
		},

		mounted() {

		},
		methods: {
			timeFormate(timeStamp) {
				let year = new Date(timeStamp).getFullYear();
				let month = new Date(timeStamp).getMonth() + 1 < 10 ? "0" + (new Date(timeStamp).getMonth() + 1) :
					new Date(timeStamp).getMonth() + 1;
				let date = new Date(timeStamp).getDate() < 10 ? "0" + new Date(timeStamp).getDate() : new Date(timeStamp)
					.getDate();
				let hh = new Date(timeStamp).getHours() < 10 ? "0" + new Date(timeStamp).getHours() : new Date(timeStamp)
					.getHours();
				let mm = new Date(timeStamp).getMinutes() < 10 ? "0" + new Date(timeStamp).getMinutes() : new Date(
					timeStamp).getMinutes();
				let ss = new Date(timeStamp).getSeconds() < 10 ? "0" + new Date(timeStamp).getSeconds() : new Date(
					timeStamp).getSeconds();
				var d = new Date(timeStamp).getDay();
				let day;
				if (d == 0) {
					day = "日";
				} else if (d == 1) {
					day = "一";
				} else if (d == 2) {
					day = "二";
				} else if (d == 3) {
					day = "三";
				} else if (d == 4) {
					day = "四";
				} else if (d == 5) {
					day = "五";
				} else if (d == 6) {
					day = "六";
				}
				// this.nowTime = year + "." + month + "." + date + "星期" + day + hh + ":" + mm + ':' + ss;
				this.nowDate = year + "." + month + "." + date;
				this.nowWeek = "星期" + day;
				this.nowtime = hh + ":" + mm + ':' + ss
			},
			nowTimes() {
				this.timeFormate(new Date());
				setInterval(this.nowTimes, 1000);
				this.clear()
			},
			clear() {
				clearInterval(this.nowTimes)
				this.nowTimes = null;
			}

		}
	}
</script>

<style scoped>
	.head {
		background: url(../assets/images/dj_tops.png) no-repeat center;
		background-size: 100% 100%;
		height: 200px;
		text-align: center;
		line-height: 200px;
		position: relative;
	}

	.head .bt {
		font-size: 80px;
		color: #fff;
		text-align: center;
		text-shadow: 0rem 0rem 0.5rem rgb(130 165 255 / 27%);
		background: linear-gradient(to top, #FFFFFF 55%, #FFFFFF 100%, #FFFFFF 55%, #FFFFFF 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		letter-spacing: 20px;
		font-family: 'RuiZiZhenYan';
	}

	.head .timebox {
		font-size: 36px;
		color: #3E9BFA;
    /* color:#fff; */
		line-height: 90px;
		position: absolute;
		right: 60px;
		top: 0;
		display: flex;
		align-items: center;
	}

	.head .date {
		font-family: 'PangMenZhengDao';
		letter-spacing: 3px;
	}

	.head .week {
		font-family: 'PangMenZhengDao';
		margin-left: 20px;
	}

	.head .time {
		font-size: 46px;
		color: #fff;
		font-family: 'Arial';
		font-weight: bold;
		margin-left: 20px;
	}
</style>
